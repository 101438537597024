import React, { useEffect } from 'react';
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layouts';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Granim from 'granim';

function App() {
  useEffect(() => {
    new Granim({
      element: '#canvas-complex',
      direction: 'diagonal',
      isPausedWhenNotInView: true,
      states: {
        'default-state': {
          gradients: [
            // Each gradient transition is an array of colors
            ['#000000', "rgb(28, 2, 102)", '#FF0000'],
            ['#530303', '#A9A9A9', 'rgb(1, 31, 2)'] // Black, Red, Violet, Green
            // You can add more gradient transitions here if desired
            
          ],
          transitionSpeed: 3000 // Adjust the transition speed as needed
        }
      }
    });
  }, []);

  return (
    <>
      <canvas id="canvas-complex"></canvas>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
