import { Link } from 'react-router-dom'
import LogoTitle from '../../assets/images/logo-s.png'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'

import Loader from 'react-loaders'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const nameArray = ['m', 'e', 'r']
  const jobArray = ['S', 't', 'u', 'd', 'e', 'n', 't']

  useEffect(() => {
    // Store the id of the timeout
    const timerId = setTimeout(() => {
        setLetterClass("text-animate-hover")
    }, 4000);
  
    // Return a function to clear the timeout
    return () => clearTimeout(timerId);
  }, []) // The empty array ensures this effect is only applied once

  return (
    <>
    <div className="container home-page">
      <div className="text-zone">
        <h1>
          <span className={letterClass}>H</span>
          <span className={`${letterClass}_12`}>i</span>
          <br />
          <span className={`${letterClass}_13`}>i</span>
          <span className={`${letterClass}_14`}>`m</span>

          <img src={LogoTitle} alt="developer" />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={nameArray}
            idx={15}
          />
          <br />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={jobArray}
            idx={22}
          />
        </h1>
        <h2>Hochschule für Wirtschaft und Recht Berlin</h2>
        <Link to="/contact" className="flat-button">
          CONTACT ME
        </Link>
      </div>
      
    </div>
    <Loader type="pacman"/>
    </>
  )
}

export default Home
