import "./index.scss"
import LogoS from "../../assets/images/logo-s.png"
import LogoSubtitle from "../../assets/images/logo-sub.png"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEnvelope, faHome, faUser} from "@fortawesome/free-solid-svg-icons"
import {faDiscord, faInstagram, faLinkedin} from "@fortawesome/free-brands-svg-icons"

import { Link, NavLink } from "react-router-dom"

const Sidebar = () => (
    <div className="nav-bar">
        <Link className="logo" to="/">
           <img src={LogoS} alt="logo" />
           <img className="sub-logo" src={LogoSubtitle} alt="Ömer" />
         </Link>
        <nav>
            <NavLink exact="true" activeclass="active" to="/">
               <FontAwesomeIcon icon={faHome} color="#ffffff"/>
             </NavLink>
             <NavLink exact="true" activeclass="active" className="about-link" to="/about">
               <FontAwesomeIcon icon={faUser} color="#ffffff"/>
             </NavLink>
             <NavLink exact="true" activeclass="active" className="contact-link" to="/contact">
               <FontAwesomeIcon icon={faEnvelope} color="#ffffff"/>
             </NavLink>
        </nav>
        <ul>
            <li>
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/%C3%B6mer-faruk-yildiz-44a56a289/">
                    <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
                </a>
            </li>
            <li>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/farukkkabii/">
                    <FontAwesomeIcon icon={faInstagram} color="#4d4d4e" />
                </a>
            </li>
            <li>
                <a target="_blank" rel="noreferrer" href="discord:live:goat Faruk#8946">
                    <FontAwesomeIcon icon={faDiscord} color="#4d4d4e" />
                </a>
            </li>
        </ul>
    </div>

)

export default Sidebar