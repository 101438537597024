import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngular,
  faCss3,
  faGitAlt,
  faHtml5,
  faJs,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    // Store the id of the timeout
    const timerId = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)

    // Return a function to clear the timeout
    return () => clearTimeout(timerId)
  }, []) // The empty array ensures this effect is only applied once

  return (
    <>
    <div className="container about-page">
      <div className="text-zone">
        <h1>
          <AnimatedLetters
            letterClass={letterClass}
            strArray={['A', 'b', 'o', 'u', 't', '', 'm', 'e']}
            idx={15}
          />
        </h1>
        <p>
        Hello! I'm Ömer Faruk Yildiz, a dedicated<br></br>
        and proactive student of Business Informatics<br></br>
        with a passion for technology and its applications<br></br>
        in the modern business environment.<br></br>
        I am currently at the exciting crossroads of my<br></br>
        educational journey, actively seeking opportunities<br></br>
        to launch my career in the IT sector. <br></br>
        Knowdlge: Html5,CSS,React,Git,Js,Java <br></br>
        <br></br>
        Contact Me! <br></br>
        </p>
        
        
      </div>

      <div className="stage-cube-cont">
        <div className="cubespinner">
          <div className="face1">
            <FontAwesomeIcon icon={faAngular} color="#DD0031" />
          </div>
          <div className="face2">
            <FontAwesomeIcon icon={faHtml5} color="#F06529" />
          </div>
          <div className="face3">
            <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
          </div>
          <div className="face4">
            <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
          </div>
          <div className="face5">
            <FontAwesomeIcon icon={faJs} color="#EFD81D" />
          </div>
          <div className="face6">
            <FontAwesomeIcon icon={faGitAlt} color="#EC" />
          </div>
        </div>
      </div>
    </div>
    <Loader type="pacman" />
    </>
  )
}

export default About
